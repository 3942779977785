@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Distressed&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Alkatra:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Moonrocks&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Glitch&display=swap'); */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: #FFFFFF;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}